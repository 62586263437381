import * as React from "react"
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import FormattedText from '../components/formatted-text'
import Seo from '../components/seo'
import Menu from '../components/menu'
import Footer from '../components/footer'

const NotFoundPage = () => (
        <>
          <Seo/>
          <Container fluid="md">
            <Row>
              <Col>
                <Menu/>
              </Col>
            </Row>
            <Row id="content-row">
            <Col>
            <FormattedText title= {"404"} content={"Página no encontrada"} />
            </Col>
            </Row>
            <Footer/>
          </Container>
        </>
)

export default NotFoundPage
